body {
  background-color: #fbf9ec;
}

.logo {
  height: 40vmin;
  pointer-events: none;
}


